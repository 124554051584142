<template>
  <tj-dialog v-model="visible" title="管理员设置" @close="onClose">
    <el-container>
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="姓名" prop="name"></el-table-column>
          <el-table-column label="手机号" align="center">
            <template #default="{ row }">
              <el-link
                v-if="row.mobile"
                type="primary"
                :icon="Phone"
                :underline="false"
                :href="`tel:${row.mobile}`"
              >{{ row.mobile }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="112">
            <template #default="{ row }">
              <el-button
                v-if="row.id === currentUserId"
                type="danger"
                @click="revoke(row.id)"
              >撤销</el-button>
              <el-button
                v-else
                type="primary"
                @click="assign(row.id)"
              >设为管理员</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getAdminList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </tj-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { Phone } from '@element-plus/icons-vue';
import { useDialog, usePagination } from '@tj/ui';
import request from '@/hooks/request';

const detail = reactive({
  company_id: 0,
  admin_id: 0,
});
const currentUserId = ref(0);

const { page } = usePagination();
const tableData = ref([]);
function getAdminList() {
  request.get('/admin/company/adminList', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
defineExpose({ open });
onOpen(({
  id,
  manage_user_id: manageUserId,
}) => {
  detail.company_id = id;
  currentUserId.value = manageUserId;
  getAdminList();
});
function onClose() {
  tableData.value = [];
  detail.company_id = 0;
  detail.admin_id = 0;
  currentUserId.value = 0;
}

const emit = defineEmits(['submit']);

function assign(adminId) {
  detail.admin_id = adminId;
  request.post('/admin/company/assignManager', detail).then(() => {
    emit('submit');
    close();
  });
}

function revoke(adminId) {
  detail.admin_id = adminId;
  request.post('/admin/company/revokeManager', {
    company_id: detail.company_id,
  }).then(() => {
    emit('submit');
    close();
  });
}
</script>
